<template>
<v-row class="statuses">
    <v-col cols="3" v-if="loading">
        <div class="statuses__headline">
            <v-skeleton-loader
                type="heading"
                width="100%"
                height="100%"
                class="ml-4"
            />
        </div>
        <div class="statuses__items">
            <v-skeleton-loader
                type="list-item"
                width="100%"
                height="100%"
                v-for="i in 7"
                :key="i"
            />
        </div>
    </v-col>
    <v-col cols="3" v-if="!loading">
        <div class="statuses__headline">
            <h3>Статусы</h3>
            <v-btn text icon @click="createNewStatus" v-if="hasRight('dealStatuses.manage')">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </div>
        <v-text-field
                label="Поиск"
                class="evi-text-field mt-5 mr-3"
                color="#44D370"
                v-model="search"
                clearable
        >
            <template v-slot:append>
                <img class="mt-1" width="15" :src="require('@/assets/img/analytics/search.svg')" alt="">
            </template>
        </v-text-field>
        <div class="statuses__items">
            <div class="statuses__item" @click="selectStatus(item)" v-for="item in filteredStatuses" :key="item.id">
                <span class="color" :style="{backgroundColor: `${item.color}`}"/>
                <span class="title-name">
                    {{item.name}}
                    <span class="title-line" :class="{'active' : item.id === selectedStatus.id}" />
                </span>
            </div>
        </div>
    </v-col>
    <v-divider vertical></v-divider>
    <v-col class="ml-5" v-if="loading">
        <div class="statuses__headline mb-2">
            <v-skeleton-loader
                type="heading"
                width="100%"
                height="100%"
            />
        </div>
        <div class="statuses__fields">
            <v-skeleton-loader
                type="heading"
                width="100%"
                height="100%"
                class="mb-7"
            />
            <v-skeleton-loader
                    type="heading"
                    width="100%"
                    height="100%"
                    class="mb-7"
            />
            <v-skeleton-loader
                type="card, actions"
                width="350"
                height="100%"
            />
        </div>
    </v-col>
    <v-col class="ml-5" v-if="!loading && statuses.length">
        <div class="statuses__headline mb-2">
            <h4 class="mt-1">СТАТУС — {{selectedStatus.name}}</h4>
            <v-btn class="evi-delete-button" @click="deleteStatusModal = true" color="#ffffff" height="59" v-if="hasRight('dealStatuses.manage') && editable">
                Удалить статус
                <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
        </div>
        <div class="statuses__fields">
            <StatusForm :selectedStatus="selectedStatus" :editable="editable" @reset="reset" @saveStatus="saveStatus"/>
        </div>
        <v-dialog
                v-model="deleteStatusModal"
                max-width="600"
        >
            <v-card>
                <v-card-title class="text-h5">
                    Удаление статуса
                </v-card-title>
                <v-card-text>Вы действительно хотите удалить статус <span class="font-weight-bold">"{{selectedStatus.name}}"</span>?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            text
                            @click="deleteStatusModal = false"
                    >
                        Отмена
                    </v-btn>
                    <v-btn
                            color="red"
                            text
                            @click="deleteStatusItem"
                    >
                        Удалить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-col>
</v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import StatusForm from "../../components/deal-statuses/StatusForm";
import {hasRight} from '@/utils/access/hasRight';

export default {
    name: "DealStatuses",
    components: {StatusForm},
    data: () => ({
        localStatuses: '',
        selectedStatus: {},
        deleteStatusModal: false,
        search: '',
    }),
    computed: {
        ...mapState("deal-statuses", ["statuses",]),
        ...mapState("common", ["loading"]),
        editable () {
            return !(this.selectedStatus.id === 1 || this.selectedStatus.id === 3 || this.selectedStatus.id === 4);
        },
        filteredStatuses () {
            if(!this.search){
                return this.localStatuses;
            }
            return this.localStatuses.filter(i => {
                return i.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
            })
        },
    },
    methods: {
        ...mapActions({
            getStatuses: 'deal-statuses/getStatuses',
            createStatus: 'deal-statuses/createStatus',
            deleteStatus: 'deal-statuses/deleteStatus',
            getSelectedStatus: 'deal-statuses/getSelectedStatus',
            saveSelectedStatus: 'deal-statuses/saveSelectedStatus',
        }),
        hasRight: hasRight,
        updateStatuses () {
            this.localStatuses = this.statuses;
        },
        selectStatus (item) {
            if (item) {
                this.getSelectedStatus(item.id).then((response) => {
                    this.selectedStatus = response;
                })
            }
        },
        reset () {
            this.selectStatus(this.selectedStatus);
        },
        saveStatus(item) {
            this.saveSelectedStatus(item);
        },
        statusesInit () {
            this.getStatuses().then(() => {
                this.updateStatuses();
                if (!this.selectedStatus.name) {
                    this.selectStatus(this.localStatuses[0]);
                }
            });
        },
        createNewStatus () {
            this.createStatus().then(() => {
                this.statusesInit();
            })
        },
        deleteStatusItem () {
            this.deleteStatus(this.selectedStatus).then(() => {
                this.selectedStatus = {};
                this.statusesInit();
            });
            this.deleteStatusModal = false;
        }
    },
    mounted() {
        this.statusesInit();
    },

}
</script>

<style lang="scss" scoped>
</style>
